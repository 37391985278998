import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSweetalert2 from 'vue-sweetalert2';
import BootstrapVue from 'bootstrap-vue';
import 'vuetify/dist/vuetify.min.css';
import vuetify from './plugins/vuetify';

import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);
Vue.use(BootstrapVue)
Vue.use(VueAxios, axios)
axios.defaults.baseURL = 'https://bdd7w9ysj6.execute-api.us-west-1.amazonaws.com/app';
Vue.config.productionTip = false

new Vue({
  vuetify,
  render: h => h(App),
}).$mount('#app')
