<template>
  <div id="app">
    <header class="header">
      <div class="logotype">
        <img src="@/assets/logo-light.png" alt="Logo">
      </div>
      <a href="https://juntaspodemosahorrar.institutochihuahuensedelasmujeres.gob.mx/juntas/" class="btn btn-success back-button">
        Regresar al sitio web
      </a>
    </header>
    <main class="container py-5">
      <div class="row text-center">
        <h5 class="fw-bold">PANTALLA DE REGISTRO</h5>
        <p>
          Gracias por unirte al programa <b>JUNTAS PODEMOS AHORRAR</b>, para
          registrarte llena el siguiente formulario con tu información. Ten en 
          cuenta que solo puede haber un registro por mujer, por lo que te pedimos
          que revises bien los datos antes de dar clic en el botón <b>REGISTRARME</b>.
        </p>
      </div>
      <div class="form-card">
        <h5 id="titulo" class="mb-0">Bienvenida al registro JUNTAS PODEMOS AHORRAR</h5>
        <form id="form-registro" class="needs-validation mt-5 mb-5" novalidate>
          <!------------- INFORMACION DE CUENTA ------------->
          <div class="row mb-5">
            <div class="col-12">
              <!-- <h5>Información de tu cuenta</h5> -->
            </div>
            <div class="col-12 col-md-4 mb-3 ">
              <div class="form-group">
                <label for="input-curp">Cual es su CURP</label>
                <input 
                  type="text" 
                  class="form-control text-uppercase" 
                  id="input-curp" 
                  pattern=".{18}"
                  v-model="objeto.curp"
                  @keypress="validateCurp($event, objeto.curp, 18)"
                  placeholder="XXAX010101XXXXXX1" 
                  required
                >
                <a class="link-curp" href="https://www.gob.mx/curp/" target="_blank">Consulta tu CURP aquí</a>
              </div>
            </div>
            <div class="col-12 col-md-4 mb-3 ">
              <div class="form-group">
                <label for="input-curp">Email</label>
                <input 
                  type="email" 
                  class="form-control" 
                  id="input-email" 
                  v-model="objeto.email"
                  placeholder="Correo electrónico" 
                >
              </div>
            </div>
          </div>

          <!------------- INFORMACION PERSONAL ------------->
          <div class="row mb-5">
            <div class="col-12 col-md-3 mb-3 ">
              <div class="form-group">
                <label for="input-nombre">Nombre(s)</label>
                <input 
                  type="text" 
                  class="form-control" 
                  id="input-nombre" 
                  v-model="objeto.nombre"
                  @keypress="validateText($event, objeto.nombre, 40)"
                  placeholder="Escribe aqui tu nombre o nombres." 
                  required
                >
              </div>
            </div>
            <div class="col-12 col-md-3 mb-3 ">
              <div class="form-group">
                <label for="input-apellido-paterno">Apellido Paterno</label>
                <input 
                  type="text" 
                  class="form-control" 
                  id="input-apellido-paterno" 
                  v-model="objeto.apellido_paterno"
                  @keypress="validateText($event, objeto.apellido_paterno, 40)"
                  placeholder="Escribe aqui tu apellido paterno." 
                  required
                >
              </div>
            </div>
            <div class="col-12 col-md-3 mb-3 ">
              <div class="form-group">
                <label for="input-apellido-materno">Apellido Materno</label>
                <input 
                  type="text" 
                  class="form-control" 
                  id="input-apellido-materno"
                  v-model="objeto.apellido_materno"
                  @keypress="validateText($event, objeto.apellido_materno, 40)"
                  placeholder="Escribe aqui tu apellido materno." 
                  required
                >
              </div>
            </div>
            <div class="col-12 col-md-3 mb-3">
              <div class="form-group">
                <label for="input-fecha-nacimiento">Fecha de nacimiento</label>
                <input 
                  type="date" 
                  class="form-control" 
                  :class="{ 'is-invalid-date': !isAdult }"
                  id="input-fecha-nacimiento"
                  v-model="objeto.fecha_nacimiento"
                  required
                >
              </div>
            </div>
            <div class="col-12 col-md-2 mb-3 ">
              <div class="form-group">
                <label for="input-edad">Edad</label>
                <input 
                  type="text" 
                  class="form-control" 
                  id="input-edad"
                  :class="{ 'is-invalid-date': !isAdultEdad }"
                  v-model="objeto.edad"
                  @keypress="validateNumber($event, objeto.edad, 2)"
                  placeholder="Ej. 24" 
                  required
                >
              </div>
            </div>
            <div class="col-12 col-md-4 mb-3 ">
              <div class="form-group">
                <label for="input-calle">Calle</label>
                <input 
                  type="text" 
                  class="form-control" 
                  id="input-calle"
                  v-model="objeto.calle"
                  placeholder="Ej. Av. Insurgentes" 
                  required
                >
              </div>
            </div>
            <div class="col-12 col-md-2 mb-3 ">
              <div class="form-group">
                <label for="input-exterior">Num. Exterior</label>
                <input 
                  type="text" 
                  class="form-control" 
                  id="input-exterior"
                  v-model="objeto.exterior"
                  @keypress="validateNumber($event, objeto.exterior, 5)"
                  placeholder="Ej. 123" 
                  required
                >
              </div>
            </div>
            <div class="col-12 col-md-4 mb-3">
              <div class="form-group">
                <label for="input-municipio">Municipio</label>
                <select 
                  class="form-control" 
                  id="input-municipio" 
                  v-model="objeto.id_municipio"
                  required
                >
                  <option selected disabled class="text-hide" value="">Selecciona una opción.</option>
                  <option v-for="municipio in municipios" :value="municipio.id_municipio" :key="municipio.id_municipio">{{ municipio.nombre_municipio }}</option>
                </select>
              </div>
            </div>
            <div class="col-12 col-md-3 mb-3" v-if="objeto.id_municipio != ''">
              <div class="form-group">
                <label for="input-colonia">Colonia</label>
                <select class="form-control" id="input-colonia" required v-model="objeto.colonia">
                  <option selected disabled class="text-hide" value="">Selecciona una opción.</option>
                  <option v-for="colonia in colonias" :value="colonia.value" :key="colonia.id">{{ colonia.value }}</option>
                </select>
              </div>
            </div>
            <div class="col-12 col-md-3 mb-3 ">
              <div class="form-group">
                <label for="input-telefono">Tel. celular</label>
                <input 
                  type="text" 
                  class="form-control" 
                  id="input-telefono"
                  pattern=".{10}"
                  v-model="objeto.celular"
                  @keypress="validateNumber($event, objeto.celular, 10)"
                  placeholder="Escribe tu número de teléfono." 
                  required
                >
              </div>
            </div>
          </div>

          <!------------- INFORMACION PROFESIONAL ------------->

          <div class="row mb-5">
            <div class="col-12 col-md-3 mb-3 ">
              <div class="form-group">
                <label for="input-escolaridad">Escolaridad</label>
                <select class="form-control" id="input-escolaridad" v-model="objeto.escolaridad_terminada" required>
                  <option selected disabled class="text-hide" value="">Selecciona una opción.</option>
                  <option 
                    v-for="escolaridad in escolaridadOptions" 
                    :value="escolaridad.value" 
                    :key="escolaridad.value"
                  >
                      {{ escolaridad.text }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-12 col-md-3 mb-3 ">
              <div class="form-group">
                <label for="input-estado-civil">Estado civil</label>
                <select class="form-control" id="input-estado-civil" v-model="objeto.estado_civil" required>
                  <option selected disabled class="text-hide" value="">Selecciona una opción.</option>
                  <option 
                    v-for="estadoCivil in estadoCivilOptions" 
                    :value="estadoCivil.value" 
                    :key="estadoCivil.value"
                  >
                      {{ estadoCivil.text }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-12 col-md-3 mb-3 ">
              <div class="form-group">
                <label for="input-ocupacion">Ocupación</label>
                <Multiselect
                  multiple
                  v-model="objeto.ocupacion"
                  required
                  :options="[
                    'Empleada domestica',
                    'Empleada en Empresa Privada o A.C',
                    'Empresaria',
                    'Gobierno',
                    'Hogar',
                    'Maquiladora',
                    'Negocio propio',
                    'Otro',
                    'Pensionada'
                  ]"                          
                ></Multiselect>
              </div>
            </div>
            <div class="col-12 col-md-3 mb-3 ">
              <div class="form-group">
                <label for="input-ingreso-mensual">Ingreso mensual</label>
                <select class="form-control" id="input-ingreso-mensual" v-model="objeto.ingreso_mensual" required>
                  <option selected disabled class="text-hide" value="">Selecciona una opción.</option>
                  <option 
                    v-for="ingresos in ingresosOptions" 
                    :value="ingresos.value" 
                    :key="ingresos.value"
                  >
                      {{ ingresos.text }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-12 col-md-2 mb-3 ">
              <div class="form-group">
                <label for="input-hijos">Tiene hijos</label>
                <select class="form-control" id="input-tiene-hijos" v-model="hijos">
                  <option value="No" selected>No</option>
                  <option value="Si">Si</option>
                </select>
                <!-- <div class="d-flex gap-3">
                  <div class="form-check">
                    <input class="form-check-input" type="radio" id="input-hijos-si" value="Si" v-model="hijos">
                    <label class="form-check-label" for="input-hijos-si">
                      Si
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" id="input-hijos-no" value="No" v-model="hijos" checked>
                    <label class="form-check-label" for="input-hijos-no">
                      No
                    </label>
                  </div>
                </div> -->
              </div>
            </div>
            <div class="col-12 col-md-3 mb-3" v-if="hijos == 'Si'">
              <div class="form-group">
                <label for="input-cantidad-hijos">Numero de hijas/hijos</label>
                <input 
                  type="text" 
                  class="form-control" 
                  id="input-cantidad-hijos"
                  v-model="objeto.cantidad_hijos"
                  @keypress="validateNumber($event, objeto.cantidad_hijos, 2)"
                  :required="hijos == 'Si'"
                >
              </div>
            </div>
            <div class="col-12 col-md-4 mb-3" v-if="hijos == 'Si'">
              <div class="form-group">
                <label for="input-primer-hijo">A qué edad se tuvo su primer hija/hijo</label>
                <select class="form-control" id="input-primer-hijo" v-model="objeto.edad_primer_hijo" :required="hijos == 'Si'">
                  <option selected disabled class="text-hide" value="">Selecciona una opción.</option>
                  <option 
                    v-for="edadPrimerHijo in edadPrimerHijoOptions" 
                    :value="edadPrimerHijo.value" 
                    :key="edadPrimerHijo.value"
                  >
                      {{ edadPrimerHijo.text }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-12 col-md-3 mb-3">
              <div class="form-group">
                <label for="input-cantidad-dependientes">Dependientes económicos</label>
                <input 
                  type="text" 
                  class="form-control" 
                  id="input-cantidad-dependientes"
                  v-model="objeto.cantidad_dependientes_economicos"
                  @keypress="validateNumber($event, objeto.cantidad_dependientes_economicos, 2)"
                  placeholder="Cantidad de dependientes económicos"
                  required
                >
              </div>
            </div>
          </div>

          <!------------- INFORMACION DE VIOLENCIA ------------->

          <div class="row mb-5">
            <div class="col-12 col-md-4 mb-3 ">
              <div class="form-group">
                <label for="input-hijos">Ha vivido un episodio de violencia</label>
                <select class="form-control" id="input-violencia" v-model="objeto.episodio_violencia">
                  <option value="No" selected>No</option>
                  <option value="Si">Si</option>
                </select>
                <!-- <div class="d-flex gap-3">
                  <div class="form-check">
                    <input class="form-check-input" type="radio" id="input-violencia-si" value="Si" v-model="objeto.episodio_violencia">
                    <label class="form-check-label" for="input-violencia-si">
                      Si
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" id="input-violencia-no" value="No" v-model="objeto.episodio_violencia" checked>
                    <label class="form-check-label" for="input-violencia-no">
                      No
                    </label>
                  </div>
                </div> -->
              </div>
            </div>
            <div class="col-12 col-md-4 mb-3" v-if="objeto.episodio_violencia == 'Si'">
              <div class="form-group">
                <label for="input-tipo-violencia">Cuál tipo de violencia</label>
                <Multiselect
                  multiple
                  v-model="objeto.tipo_violencia"
                  :required="objeto.episodio_violencia == 'Si'"
                  :options="[
                    'Física',
                    'Psicológica',
                    'Económica',
                    'Patrimonial',
                    'Obstétrica',
                    'Sexual'
                  ]"
                ></Multiselect>
              </div>
            </div>
            <div class="col-12 col-md-4 mb-3" v-if="objeto.episodio_violencia == 'Si'">
              <div class="form-group">
                <label for="input-lugar-violencia">Lugar en que vivió los episodios de violencia</label>
                <Multiselect
                  multiple
                  v-model="objeto.lugar_violencia"
                  :required="objeto.episodio_violencia == 'Si'"
                  :options="[
                    'Familia',
                    'Trabajo',
                    'Escuela',
                    'Transporte',
                    'En la calle',
                    'En hospital'
                  ]"
                ></Multiselect>
                </select>
              </div>
            </div>
          </div>

          <!------------- INFORMACION DE REDES ------------->

          <div class="row mb-5">
            <div class="col-12 col-md-3 mb-3 ">
              <div class="form-group">
                <label for="input-redes-sociales">Cuenta con redes sociales</label>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="Facebook" id="input-facebook" v-model="objeto.redes_sociales">
                  <label class="form-check-label" for="input-facebook">
                    Facebook
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="Instagram" id="input-instagram" v-model="objeto.redes_sociales">
                  <label class="form-check-label" for="input-instagram">
                    Instagram
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="TikTok" id="input-tiktok" v-model="objeto.redes_sociales">
                  <label class="form-check-label" for="input-tiktok">
                    TikTok
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="true" id="input-otra" v-model="otra">
                  <label class="form-check-label" for="input-otra">
                    Otra...
                  </label>
                  <input type="text" class="form-control" v-if="otra" v-model="otraRedSocial">
                </div>
              </div>
            </div>
            <div class="col-12 col-md-3 mb-3 ">
              <div class="form-group">
                <label for="input-hijos">Cuenta con WhatsApp</label>
                <select class="form-control" id="input-whatsapp" v-model="whatsapp">
                  <option value="No" selected>No</option>
                  <option value="Si">Si</option>
                </select>
                <!-- <div class="d-flex gap-3">
                  <div class="form-check">
                    <input class="form-check-input" type="radio" id="input-whatsapp-si" value="Si" v-model="whatsapp">
                    <label class="form-check-label" for="input-whatsapp-si">
                      Si
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" id="input-whatsapp-no" value="No" v-model="whatsapp" checked>
                    <label class="form-check-label" for="input-whatsapp-no">
                      No
                    </label>
                  </div>
                </div> -->
              </div>
            </div>
            <div class="col-12 col-md-3 mb-3" v-if="whatsapp == 'Si'">
              <div class="form-group">
                <label for="input-whatsapp">WhatsApp</label>
                <input 
                  type="text" 
                  class="form-control" 
                  id="input-whatsapp"
                  pattern=".{10}"
                  v-model="objeto.whatsapp"
                  @keypress="validateNumber($event, objeto.whatsapp, 10)"
                  placeholder="Escribe tu número de WhatsApp" 
                  :required="whatsapp == 'Si'"
                >
              </div>
            </div>
          </div>


          <button type="button" @click="checkForm($event)" class="btn button-success me-4">Registrarme</button>
          <button type="button" class="btn btn-secondary" @click="openPage()">Cancelar</button>
        </form>
      </div>
      <div class="row text-center mb-5 mt-3">
        <p>Si tienes dudas o necesitas ayuda da <a class="link-help" href="https://juntaspodemosahorrar.institutochihuahuensedelasmujeres.gob.mx/ayuda/">clic aquí</a></p>
      </div>
    </main>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import { validateNumber, validateText, validateCurp } from "@/utils/utils.js";
import moment from 'moment';
import axios from 'axios';
import Multiselect from "vue-multiselect";

export default {
  name: 'App',
  components: {
    Multiselect
  },
  data() {
    return {
      whatsapp: "No",
      hijos: "No",
      otra: '',
      otraRedSocial: "",
      sended: false,
      municipios: [],
      escolaridadOptions: [
        { text: 'Ninguna', value: 'Ninguna' },
        { text: 'Primaria', value: 'Primaria' },
        { text: 'Secundaria', value: 'Secundaria' },
        { text: 'Preparatoria', value: 'Preparatoria' },
        { text: 'Técnico', value: 'Técnico' },
        { text: 'Profesional', value: 'Profesional' },
        { text: 'Maestría', value: 'Maestría' },
        { text: 'Doctorado', value: 'Doctorado' },
      ],
      estadoCivilOptions: [
        { text: 'Soltera', value: 'Soltera' },
        { text: 'Casada', value: 'Casada' },
        { text: 'Unión libre', value: 'Unión libre' },
        { text: 'Divorciada', value: 'Divorciada' },
        { text: 'Viuda', value: 'Viuda' },
      ],
      ocupacionOptions: [
        { text: 'Hogar', value: 'Hogar' },
        { text: 'Pensionada', value: 'Pensionada' },
        { text: 'Empleada doméstica', value: 'Empleada doméstica' },
        { text: 'Gobierno', value: 'Gobierno' },
        { text: 'Maquiladora', value: 'Maquiladora' },
        { text: 'Empleada en Empresa Privada o A.C.', value: 'Empleada en Empresa Privada o A.C.' },
        { text: 'Negocio propio', value: 'Negocio propio' },
        { text: 'Otro...', value: 'Otro...' },
      ],
      ingresosOptions: [
        { text: 'Sin ingresos', value: 'Sin ingresos' },
        { text: 'Entre $1 y $2,500', value: '1 - 2500' },
        { text: 'Entre $2,500 y $4,000', value: '2500 - 4000' },
        { text: 'Entre $4,000 y $6,000', value: '4000 - 6000' },
        { text: 'Entre $6,000 y $8,000', value: '6000 - 8000' },
        { text: '$8,000 o más', value: '+8000' },
      ],
      edadPrimerHijoOptions: [
        { text: 'Entre 10 y 14 años', value: '10 - 14' },
        { text: 'Entre 15 y 19 años', value: '15 - 19' },
        { text: 'Entre 20 y 24 años', value: '20 - 24' },
        { text: 'Entre 25 o más', value: '+25' },
      ],
      lugarViolenciaOptions: [
        { text: 'Familia', value: 'Familia' },
        { text: 'Trabajo', value: 'Trabajo' },
        { text: 'Escuela', value: 'Escuela' },
        { text: 'Transporte', value: 'Transporte' },
        { text: 'En la calle', value: 'En la calle' },
        { text: 'En hospital', value: 'En hospital' },
      ],
      tiposViolenciaOptions: [
        { text: 'Física', value: 'Física' },
        { text: 'Psicológica', value: 'Psicológica' },
        { text: 'Económica', value: 'Económica' },
        { text: 'Patrimonial', value: 'Patrimonial' },
        { text: 'Obstétrica', value: 'Obstétrica' },
        { text: 'Sexual', value: 'Sexual' },
      ],
      objeto: {
        id_tarjeta: "",
        curp: "",
        nombre: "",
        apellido_paterno: "",
        apellido_materno: "",
        id_tipo: 5,
        fecha_nacimiento: "",
        colonia: "",
        celular: "",
        estatus: "Activo",
        fecha_creacion: "",
        fecha_modificacion: "",
        // Nuevos campos
        email: "",
        edad: "",
        calle: "",
        numero_exterior: "",
        id_municipio: "",
        escolaridad_terminada: "",
        estado_civil: "",
        ocupacion: "",
        ingreso_mensual: "",
        cantidad_hijos: "",
        cantidad_dependientes_economicos: "",
        edad_primer_hijo: "",
        episodio_violencia: "No",
        tipo_violencia: "",
        lugar_violencia: "",
        redes_sociales: [],
        whatsapp: "",
      },
    }
  },
  mounted() {
    this.getMunicipios();
  },
  computed: {
    // Colonias
    colonias() {
      if(this.objeto.id_municipio != "") {
        // give me the list of colonias that are inside the selected municipio
        const colonias = this.municipios.filter(x => {
          return x.id_municipio == this.objeto.id_municipio;
        })
        // generate a random number of 4 digits
        
        const coloniasTextValue = colonias[0].colonias.map(x => {
          const random = Math.floor(Math.random() * 99999999) + 10000000;
          return { id: random  ,value: x.colonia }
        });
        // sort data by alphabetical order
        return coloniasTextValue.sort((a, b) => {
          return a.value.localeCompare(b.value);
        })
      } else return [];
    },
     // computed that checks if fecha_nacimiento is more than 18 years old
    isAdult() {
      if (this.objeto.fecha_nacimiento) {
        let fecha = this.objeto.fecha_nacimiento;
        const years = moment().diff(fecha, 'years');
        return years >= 18;
      } else {
        return true;
      }
    },
    isAdultEdad() {
      if(this.objeto.edad) return this.objeto.edad >= 18;
      else return true;
    }
  },
  methods: {
    // get municipios
    getMunicipios() {
      axios.get("/municipios_colonias").then((res) => {
        const { response = "", data = [] } = res.data;
        if (response === true) {
          this.municipios = data.sort((a, b) => {
            return a.nombre_municipio.localeCompare(b.nombre_municipio);
          })
        }
      })
      .catch((err) => {
        Swal.fire('Alerta', 'No se pudieron descargar los municipios', 'warning');
      });
    },

    // method that validate keypress only numbers
    validateNumber(event, value, max) {
      if (value) {
        if (value.toString().length + 1 > max) {
          event.preventDefault();
        } else validateNumber(event);
      } else {
        validateNumber(event);
      }
    },

    validateText(event, value, max) {
      if (value) {
        if (value.toString().length + 1 > max) {
          event.preventDefault();
        } else validateText(event);
      } else {
        validateText(event);
      }
    },

    validateLength(event, value, max) {
      if (value) {
        if (value.length + 1 > max) {
          event.preventDefault();
        }
      }
    },

    validateCurp(event, value, max) {
      if (value) {
        if (value.length + 1 > max) {
          event.preventDefault();
        } else validateCurp(event);
      } else {
        validateCurp(event);
      }
    },

    openPage() {
      window.open("https://juntaspodemosahorrar.institutochihuahuensedelasmujeres.gob.mx/juntas/", "_blank");
    },

    checkForm(e) {
      var form = document.getElementById("form-registro");
      if (form.checkValidity() === true) {
        if(!this.isAdult || !this.isAdultEdad) {
          this.$swal("Alerta", "La edad y fecha de nacimiento deben ser mayor de 18 años", "warning");
        } else  {
          this.handleSubmit()
        }
      }
      form.classList.add('was-validated'); 
      e.preventDefault();
      e.stopPropagation(); 
    },

    handleSubmit() {
      this.objeto.curp = this.objeto.curp.toUpperCase();
      if(this.otraRedSocial != "") this.objeto.redes_sociales.push(this.otraRedSocial);
      this.objeto.fecha_creacion = moment().format("YYYY-MM-DD");
      this.objeto.fecha_modificacion = moment().format("YYYY-MM-DD");
      const payload = JSON.stringify(this.objeto);
      console.log(payload);
      if(!this.sended) {
        axios
          .post("/usuarios", payload)
          .then((res) => {
            const { response = "" } = res.data;
            if (response === true) {
              window.open("https://juntaspodemosahorrar.institutochihuahuensedelasmujeres.gob.mx/recoge-tu-tarjeta/", "_self");
            } else {
              console.log(res.data)
              this.$swal("Alerta", res.data.details , "warning");
            }
          })
          .catch((error) => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
            this.$swal("Error", "Ocurrio un error, favor de comunicarse con el equipo", "error");
          });
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/main.css';
</style>
